@font-face {
  font-family: "Avenir LT W01_35 Light1475496";
  src: url("./assets/fonts/bebas_neue/BebasNeue-Regular.woff2") format("woff2"),
    url("./assets/fonts/bebas_neue/BebasNeue-Regular.woff") format("woff");
}
@font-face {
  font-family: "Proxima-Nova";
  src: url("./assets/fonts/bebas_neue/Proxima-Nova-Font.otf") format("otf");
}

/* @font-face {
  font-family: "Proxima-Nova";
  src: url("./assets/fonts/proxima_nova/Proxima-Nova-Font.otf") format("truetype");
} */

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova/Proxima-Nova-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova/Proxima-Nova-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova/Proxima-Nova-Extrabold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova/Proxima-Nova-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova/Proxima-Nova-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova/Proxima-Nova-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova/Proxima-Nova-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffc300;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.image-gallery-thumbnail img {
  width: 100px;
  height: 60px;
}

body {
  line-height: 1.6;
  font-size: 16px;
  color: #2b2c28;
  background: linear-gradient(0deg, rgba(254, 250, 251, 1) 98.5%, rgba(190, 190, 190, 1) 100%);
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {
  --spacing-padding: 20px;
  --wide-margin: 6%;
}

.container {
  height: 100%;
  margin-left: var(--wide-margin);
  margin-right: var(--wide-margin);
  padding: 0 var(--spacing-padding);
  box-sizing: border-box;
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-content-space-between {
  justify-content: space-between;
}

.p-x-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-l-14 {
  padding-left: 14px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-y-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.p-b-50 {
  padding-bottom: 50px;
}

.carousel.carousel-slider .control-arrow {
  top: calc(50% - 29px) !important;
  height: min-content !important;
  opacity: 0.9 !important;
  font-size: 58px !important;
}
.carousel.carousel-slider .control-arrow:hover {
  top: calc(50% - 29px) !important;
  height: min-content !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.522) !important;
  font-size: 58px !important;
}
.carousel .control-next.control-arrow:before {
  border-left: 20px solid #fff !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 20px solid #fff !important;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-top: 20px solid transparent !important;
  border-bottom: 20px solid transparent !important;
}

.repair_html_text_Print p,
ul {
  line-height: 1.2;
  margin: 0px;
}

.repair_html_text_copy p,
ul {
  line-height: 1.2;
  margin: 0px;
}

.description_html_text_Print {
  /* padding: 0rem 1.25rem; */
  word-wrap: break-word;
}

img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.repair_html_text p,
ul {
  line-height: 1.2;
  margin: 0px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
