.ContactUsComponent {
  padding: 0 20px;
}

.ContactUs_Component_text {
  margin-bottom: 1rem;
  font-weight: 500;
  color: #353535;
}

.ContactUs_Component_address_text {
  font-family: "Proxima-Nova", sans-serif;
  color: #353535;
  overflow-wrap: break-word;
}

.ContactUs_address_box {
  margin: 2rem 0;
}

.ContactUs_Component_address {
  text-decoration: none;
  color: #ffc300;
}

.ContactUs_Component_address:hover {
  color: #6799b2;
}

.ContactUs_Container {
  /* max-width: 797px; */
  margin: auto;
  /* display: flex; */
}

.Submit_loading_btn {
  color: hsl(0, 0%, 100%);
  background: #1010104d;
  padding: 19px;
  border: none;
  cursor: pointer;
  font-weight: 400;
  width: 100%;
  font-size: 20px;
  line-height: 24px;
  font-family: "Bebas Neue", sans-serif;
}

.Submit_btn {
  margin-top: 5px;
  color: #141215;
  background: #facd40;
  padding: 19px;
  border: none;
  cursor: pointer;
  font-weight: 400;
  width: 100%;
  font-size: 20px;
  line-height: 24px;
  font-family: "Bebas Neue", sans-serif;
}

.Get_Submit_btn {
  margin: 25px 0;
  color: hsl(0, 0%, 100%);
  background: #ffc300;
  border-radius: 10px;
  padding: 12px 25px;
  border: none;
  cursor: pointer;
  font-family: "Bebas Neue", sans-serif;
  font-size: 24px;
}

.Get_Doc_btn {
  margin: 10px 0;
  color: hsl(0, 0%, 100%);
  background: #ffc300;
  border-radius: 10px;
  padding: 12px 25px;
  border: none;
  cursor: pointer;
  font-family: "Bebas Neue", sans-serif;
  font-size: 24px;
}

.Get_Submit_btn_Box {
  text-align: center;
}

.Get_Document_Box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Get_Document_link {
  color: #2200cc;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact_grid_box_top {
  display: flex;
}

.Contact_Page_field {
  display: flex;
  flex-direction: column;
  /* margin: 10px 10px 10px 0; */
  width: -webkit-fill-available;
}

.error_message {
  color: red;
}

@media screen and (max-width: 1121px) {
  .Get_Submit_btn_Box {
    text-align: start;
  }

  .Get_Document_Box {
    justify-content: flex-start;
  }

  .Get_Document_link {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
  }
} /* Base styles (default for large screens) */
.contact_page_input {
  border: 1px solid #dddddd;
  background: #f5f5f5;
  height: 25px;
  margin-top: 3px;
  padding: 14px 20px;
  font-size: 20px !important;
  line-height: 32px;
  font-family: "Proxima-Nova", sans-serif;
  font-weight: lighter !important;
  outline: none;
}

.contact_page_input::placeholder {
  color: #cccccc;
  font-family: "Proxima-Nova", sans-serif;
  font-weight: lighter !important;
}

.contact_page_input_textarea {
  border: 1px solid #dddddd;
  background: #f5f5f5;
  margin-top: 3px;
  padding: 14px 20px;
  font-size: 20px !important;
  line-height: 32px;
  font-family: "Proxima-Nova", sans-serif;
  font-weight: lighter;
  outline: none;
}

.contact_page_input_textarea::placeholder {
  color: #cccccc;
  font-family: "Proxima-Nova", sans-serif;
  font-weight: lighter;
}

@media only screen and (max-width: 1469px) {
  .contact_page_input {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .contact_page_input_textarea {
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

.contact-title {
  font-family: "Proxima Nova", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #facd40;
}

.contact-phone {
  font-family: "Proxima Nova", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  color: #fff;
}

.get-in-touch-text {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  color: #141215;
  padding-top: 0px !important;
  text-align: center;
}

/* Medium screens (tablets) */

@media (max-width: 1484px) {
  .contact-title {
    margin-bottom: 28px;
  }
  .contact-phone {
    font-size: 21px;
    line-height: 36px;
  }
}

@media (max-width: 1425px) {
  .contact-title {
    margin-bottom: 28px;
  }
  .contact-phone {
    font-size: 18px;
    line-height: 36px;
  }
}

@media (max-width: 1301px) {
  .contact-title {
    margin-bottom: 28px;
  }
  .contact-phone {
    font-size: 16px;
    line-height: 36px;
  }
}

@media (max-width: 1301px) {
  .contact-title {
    font-size: 22px;
    line-height: 30px;
  }

  .contact-phone {
    font-size: 20px;
    line-height: 36px;
  }
}
@media (max-width: 992px) {
  .contact_page_input {
    font-size: 20px !important;
    line-height: 28px;
    padding: 12px 18px;
  }

  .contact_page_input_textarea {
    font-size: 20px !important;
    line-height: 28px;
    padding: 12px 18px;
  }

  .contact-title {
    font-size: 22px;
    line-height: 30px;
  }

  .contact-phone {
    font-size: 20px;
    line-height: 36px;
  }

  .get-in-touch-text {
    font-size: 28px;
    line-height: 32px;
  }
}

/* Small screens (mobile) */
@media (max-width: 576px) {
  .contact_page_input {
    font-size: 18px !important;
    line-height: 24px;
    padding: 10px 16px;
  }

  .contact_page_input_textarea {
    font-size: 18px !important;
    line-height: 24px;
    padding: 10px 16px;
  }

  .contact-title {
    font-size: 20px;
    line-height: 28px;
  }

  .contact-phone {
    font-size: 18px;
    line-height: 32px;
  }

  .get-in-touch-text {
    font-size: 24px;
    line-height: 28px;
  }
}
