.header_background {
  background: #ebebeb;
}

.header_logo {
  opacity: 0.8;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-property: all;
}

.header_logo:hover {
  opacity: 1;
}

.header {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-top-color: #ffc300;
  border-right-color: #ffc300;
  border-bottom-color: #ffc300;
  border-left-color: #ffc300;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px -2px 16px #000;
  animation: headerAnimation 5s;
}

@keyframes headerAnimation {
  from {
    top: -100%;
  }

  to {
    top: 0%;
  }
}

.header_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 20px;
}

.header_language_active_btn {
  border: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top-width: 0;
  border-bottom-width: 0;
  color: #141215;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 500;
  font-size: 20px;
  background: #ffc300;
  height: auto;
}
@media only screen and (max-width: 1454px) {
  .header_language_active_btn {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.header_language_btn {
  border: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-width: 0;
  border-bottom-width: 0;
  color: #141215;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 500;
  font-size: 20px;
  background: #f5f5f5;
  height: auto;
}
@media only screen and (max-width: 1454px) {
  .header_language_btn {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.header_contact_box {
  min-width: 175px;
  padding-left: 15px;
}

.header_contact_text {
  color: #ffc300;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  text-decoration: none;
}

.header_slogan_box {
  min-width: 175px;
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.header_slogan_text {
  color: #353535;
  text-transform: uppercase;
  font-weight: 700;
}

.header_center_box {
  display: flex;
  flex-wrap: wrap;
}

.header_navigation_box_lg_screen {
  display: flex;
  justify-content: flex-end;
}

.header_navigation_box_md_screen {
  display: none;
}

.header_navigation_label_active {
  padding-top: 14px;
  border-top-width: 0;
  padding-bottom: 14px;
  border-bottom-width: 0;
  color: #ffc300;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 500;
  font-size: 20px;
  background: #141215;
  height: auto;
}
@media only screen and (max-width: 1454px) {
  .header_navigation_label_active {
    padding-bottom: 7px;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-top: 7px;
  }
}

.header_navigation_label {
  padding-top: 14px;
  border-top-width: 0;
  padding-bottom: 14px;
  border-bottom-width: 0;
  color: #141215;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 500;
  font-size: 20px;
  background: #fff;
  height: auto;
}
@media only screen and (max-width: 1454px) {
  .header_navigation_label {
    padding-bottom: 7px;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-top: 7px;
  }
}

@media only screen and (max-width: 1454px) {
  .header_navigation_label_active {
    font-size: 16px;
    padding-bottom: 7px;
    display: flex;
    align-items: center;
    padding-top: 7px;
  }
}

.header_navigation_label:hover {
  color: #ffc300;
}

.header_navigation_List_Menu_btn {
  font-size: 40px !important;
  font-weight: bold !important;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 20px;
  border-top-width: 0;
  padding-bottom: 20px;
  cursor: pointer;
}

.header_navigation_list_box_md_screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_navigation_menu_close {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1244px) {
  .header_navigation_box_lg_screen {
    display: none;
  }

  .header_navigation_box_md_screen {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 732px) {
  .header_box {
    padding: 5px 20px;
  }

  .header_center_box {
    justify-content: center;
    margin: 10px 0;
  }

  .header_contact_box {
    padding-left: 0px;
  }

  .header_contact_text {
    text-align: center;
  }

  .header_slogan_box {
    margin-top: 10px;
    padding-right: 0;
    text-align: center;
  }
}

.footer {
  background: #141215;
}

.Footer_text {
  font-family: "Proxima-Nova", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 48px;
  text-align: center;
}
@media only screen and (max-width: 1454px) {
  .Footer_text {
    font-size: 16px;
    line-height: 36px;
  }
}

.Footer_text_Web_Link {
  text-decoration: none;
  /* line-height: 48px; */
  color: #facd40;
  cursor: pointer;
  margin-left: 7px;
}

.Footer_text_Web_Link:hover {
  color: #facd40;
}

.Footer_social_media_box {
}

.Footer_social_media {
  display: flex;
}

.Footer_social_media_Icon {
  width: 70px;
  height: 70px;
  margin-right: 10px;
}
@media only screen and (max-width: 1454px) {
  .Footer_social_media_Icon {
    width: 40px;
    height: 40px;
  }
}

.footer_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 0;
}
@media only screen and (max-width: 1454px) {
  .footer_box {
    padding: 50px 0;
  }
}

.Footer_Follow_Us {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 400;
  font-family: "Bebas Neue", sans-serif !important;
}
@media only screen and (max-width: 1454px) {
  .Footer_Follow_Us {
    font-size: 20px;
  }
}

.Footer_Slogan {
  font-family: "Bebas Neue", sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 48px;
  color: #ffffff;
}

@media only screen and (max-width: 1454px) {
  .Footer_Slogan {
    font-size: 20px;
    line-height: 26px;
  }
}
.responsive-footer-logo {
  height: 80px;
  width: auto; /* Maintain aspect ratio */
  cursor: pointer;
}
@media only screen and (max-width: 1454px) {
  .responsive-footer-logo {
    height: 50px;
  }
}

.footer_bottom_text {
  padding: 20px 0;
  border-top: 1px solid #facd40;
  font-family: "Proxima-Nova", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 48px;
  color: #ffffff;
}

@media (max-width: 768px) {
  .Footer_text {
    font-size: 16px;
    line-height: 26px;
  }
  .responsive-footer-logo {
    height: 50px;
  }
  .Footer_Slogan {
    font-size: 18px;
    line-height: 26px;
  }
  .Footer_Follow_Us {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .Footer_social_media_Icon {
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }
  .footer_box {
    padding: 60px 0; /* Reduce padding for tablets */
  }
}

@media (max-width: 480px) {
  .Footer_text {
    font-size: 14px;
    line-height: 28px;
  }
  .responsive-footer-logo {
    height: 40px;
  }
  .Footer_Slogan {
    font-size: 14px;
    line-height: 28px;
    text-align: center; /* Optional: Center align for better presentation */
  }
  .Footer_Follow_Us {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center; /* Optional: Center align for better presentation */
  }
  .Footer_social_media_Icon {
    width: 40px;
    height: 40px;
    margin-right: 6px;
  }
  .footer_box {
    padding: 40px 0; /* Reduce padding for mobile screens */
  }
  .Footer_social_media {
    justify-content: center;
  }
}
