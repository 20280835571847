.AboutUs_title {
  font-size: 64px;
  text-transform: uppercase;
  font-family: "Bebas Neue", sans-serif;
  overflow-wrap: break-word;
  font-weight: 500;
  line-height: 1.2;
}

.AboutUs_Sub_title {
  font-family: "Proxima-Nova", sans-serif;
  overflow-wrap: break-word;
  margin-bottom: 1rem;
  color: #353535;
  text-align: left;
}

.AboutUs_second_title {
  text-transform: uppercase;
  font-family: "Bebas Neue", sans-serif;
  overflow-wrap: break-word;
  font-weight: 600;
  line-height: 1.2;
  font-size: 36px;
  margin: 1rem 0;
}
/* Base styles */
.Who_We_Are_Box {
  background: #141215;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 99%;
}

.Who_We_Are_Title {
  text-transform: uppercase;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  color: #facd40;
}

.Who_We_Are_Sub_Title {
  color: #fff;
  padding: 28px 50px 0 50px;
  font-size: 16px;
  line-height: 1.5;
}

.about-us-box {
  padding: 50px;
  background: #fff;
}

/* Large screens (lg) */
@media (min-width: 1024px) {
  .Who_We_Are_Title {
    font-size: 48px;
    line-height: 60px;
  }

  .Who_We_Are_Sub_Title {
    padding: 30px 80px 0 80px;
    font-size: 18px;
  }

  .about-us-box {
    padding: 60px;
  }
}

/* Medium screens (md) */
@media (min-width: 768px) and (max-width: 1023px) {
  .Who_We_Are_Title {
    font-size: 38px;
    line-height: 45px;
  }

  .Who_We_Are_Sub_Title {
    padding: 25px 60px 0 60px;
    font-size: 16px;
  }

  .about-us-box {
    padding: 40px;
  }
}

/* Small screens (sm) */
@media (max-width: 767px) {
  .Who_We_Are_Title {
    font-size: 25px;
    line-height: 48px;
  }

  .Who_We_Are_Sub_Title {
    padding: 20px 30px 0 30px;
    font-size: 14px;
  }

  .about-us-box {
    padding: 20px;
  }
}
