.Our_Team_title {
  font-size: 36px;
  text-transform: uppercase;
  font-family: "Bebas Neue", sans-serif;
  overflow-wrap: break-word;
  font-weight: 500;
  line-height: 1.2;
}
/* Base styles (default for large screens) */
.Our_Team_Banner_Title {
  font-size: 64px;
  color: #ffffff;
  line-height: 72px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Bebas Neue", sans-serif;
  padding: 200px 0;
  text-align: center;
}
@media only screen and (max-width: 1454px) {
  .Our_Team_Banner_Title {
    font-size: 50px;
    line-height: 60px;
  }
}

.Our_Team_Banner_Sub_Box {
  background-color: #fff;
}

.Our_Team_Banner_Sub_Title {
  font-family: "Proxima Nova", sans-serif;
  font-weight: 400;
  line-height: 32px;
  font-size: 24px;
}

.Expertise_Text {
  font-family: "Proxima-Nova", sans-serif;
  font-weight: 700;
  line-height: 32px;
  font-size: 24px;
  padding: 30px 0;
}

.Our_Team_Members {
  background-color: #f5f5f5;
}

.Our_Team_Members_Text {
  font-size: 48px;
  line-height: 60px;
  color: #141215;
  font-weight: 400;
  font-family: "Bebas Neue", sans-serif;
}

.email-fill-icon {
  background: #facd40;
  border-radius: 50%;
  padding: 12px;
  margin-left: 10px;
  display: flex;
  cursor: pointer;
}

.info-fill-icon {
  background: #facd40;
  border-radius: 50%;
  padding: 10px;
  margin-left: 10px;
  display: flex;
  cursor: pointer;
}

.Members_Title {
  color: #141215;
  font-weight: 400;
  font-family: "Bebas Neue", sans-serif;
  font-size: 36px;
  line-height: 48px;
}

.Members_Sub_Title {
  color: #facd40;
  font-weight: 400;
  font-family: "Bebas Neue", sans-serif;
  font-size: 24px;
  line-height: 32px;
}

.Members_Dec {
  font-family: "Proxima-Nova", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-top: 30px;
}

/* Medium screens (tablets) */
@media (max-width: 992px) {
  .Our_Team_Banner_Title {
    font-size: 48px;
    line-height: 56px;
    padding: 150px 0;
  }

  .Our_Team_Banner_Sub_Box {
    padding: 80px 0;
  }

  .Our_Team_Banner_Sub_Title {
    font-size: 20px;
    line-height: 28px;
  }

  .Expertise_Text {
    font-size: 20px;
    line-height: 28px;
  }

  .Our_Team_Members_Text {
    font-size: 36px;
    line-height: 48px;
  }

  .Members_Title {
    font-size: 28px;
    line-height: 40px;
  }

  .Members_Sub_Title {
    font-size: 20px;
    line-height: 28px;
  }

  .Members_Dec {
    font-size: 20px;
    line-height: 28px;
  }
}

/* Small screens (mobile) */
@media (max-width: 576px) {
  .Our_Team_Banner_Title {
    font-size: 36px;
    line-height: 44px;
    padding: 100px 0;
  }

  .Our_Team_Banner_Sub_Box {
    padding: 50px 0;
  }

  .Our_Team_Banner_Sub_Title {
    font-size: 18px;
    line-height: 24px;
  }

  .Expertise_Text {
    font-size: 18px;
    line-height: 24px;
  }

  .Our_Team_Members_Text {
    font-size: 28px;
    line-height: 36px;
  }

  .email-fill-icon {
    padding: 8px;
  }

  .info-fill-icon {
    padding: 6px;
  }

  .Members_Title {
    font-size: 24px;
    line-height: 32px;
  }

  .Members_Sub_Title {
    font-size: 18px;
    line-height: 24px;
  }

  .Members_Dec {
    font-size: 18px;
    line-height: 24px;
  }
}
