.dashboard_left_box {
  width: 16.5%;
  margin-top: 19px;
  margin-left: 28.5px;
  max-height: 408px;
  overflow: auto;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  line-height: 37.5px;
  color: #353535;
  font-size: 20px;
  font-weight: 400;
  font-family: inherit;
  border-left: 3px solid #ffc300;
  padding-left: 40px;
  height: fit-content;
  margin-right: 20px;
  background: #fff;
}

.dashboard_selected_left_box {
  width: 20%;
  margin-top: -3px;
  margin-left: 0px;
  max-height: 408px;
  overflow: auto;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  line-height: 37.5px;
  color: #353535;
  font-size: 20px;
  font-weight: 400;
  font-family: inherit;
  border-left: 3px solid #ffc300;
  padding-left: 40px;
  height: fit-content;
  margin-right: 20px;
  background: #fff;
}

.inventory_left_box {
  width: 20%;
  margin-top: -3px;
  margin-left: 14px;
  max-height: 408px;
  overflow: auto;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  line-height: 37.5px;
  color: #353535;
  font-size: 20px;
  font-weight: 400;
  font-family: inherit;
  border-left: 3px solid #ffc300;
  padding-left: 40px;
  height: fit-content;
  margin-right: 20px;
  background: #fff;
}

.dashboard_right_box {
  width: 75%;
  animation: 1s ease-out 0s 1 slideInFromRight;
}

.dashboard_right_box_without_animation {
  width: 87%;
}

.dashboard_navigon_ink_box {
  cursor: pointer;
  font-weight: 400;
  color: #353535;
  margin-bottom: 10px;
  display: block;
  padding: 5px 5px 5px 0px;
  text-align: left;
  font-size: 20px;
  font-family: "Bebas Neue", sans-serif !important;
  line-height: 37.5px;
}

.dashboard_active_navigon_ink_box {
  cursor: pointer;
  font-weight: 400;
  color: #ffc300;
  margin-bottom: 10px;
  display: block;
  padding: 5px 5px 5px 0px;
  text-align: left;
  font-size: 20px;
  font-family: "Bebas Neue", sans-serif !important;
  line-height: 37.5px;
}

.dashboard_navigon_ink_box:hover {
  color: #ffc300;
}

.dashboard {
  display: flex;
  justify-content: center;
}

.dashboard_Slider_Img {
  height: 590px;
  background-repeat: no-repeat;
  background-size: cover !important;
}

.dashboard_slider_box {
  position: relative;
}

.dashboard_slider_model {
  z-index: 101;
  width: 270px;
  display: block;
  top: 1%;
  background-color: #ffc300;
  height: 93.5%;
  font-family: "Proxima-Nova";
  left: 1px;
  font-size: 34px;
  text-align: left;
  margin-left: 3px;
  padding-left: 40px;
  padding-top: 20px;
  padding-bottom: 0;
  padding-right: 20px;
  min-width: 270px;
  position: absolute;
}

.dashboard_slider_model_text {
  color: #353535;
  text-align: left;
  line-height: 42px;
  font-size: 25px;
  font-weight: 400;
  font-family: Tahoma, Geneva, sans-serif;
}

.dashboard_slider_model_Close_Icon {
  text-align: right;
  right: 5px;
  top: 5px;
  font-size: 20px;
  color: #fff;
  z-index: 10;
  cursor: pointer;
  position: absolute;
}

.dashboard_Slider {
  position: relative;
  cursor: pointer;
}

.dashboard_Slider_Text {
  margin-top: 5px;
  margin-right: 5px;
  width: 50%;
  position: absolute;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  padding: 10px;
  box-shadow: none;
  border: 0 solid rgba(0, 0, 0, 1);
  border-radius: 0;
  font-family: "Roboto", "Arial";
  color: #fff;
  font-size: 14px;
  text-shadow: none;
  line-height: 1.5;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-align: right;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  font-weight: 400;
}

.dashboard_slider_model_Box_Icon {
  text-align: end;
}

.dashboard_slider_model {
  transform: translateY(100%);
  animation: 1s forwards 1.5s 1 slideInFromBottom2;
}

.Carousel_navigation_part {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  margin-bottom: 30px;
  width: 50%;
  justify-content: space-between;
  margin-right: 5px;
  margin-left: 5px;
}

.Carousel_navigation_btn {
  background: #ffc300;
  opacity: 1;
  padding: 8px 10px;
  box-shadow: none;
  border: 0 solid rgba(0, 0, 0, 1);
  border-radius: 3px;
  cursor: pointer;
}

.Carousel_navigation_btn:hover {
  background: #000;
  color: #ffc300;
  font-weight: 700;
}

.MachineList_Category_box {
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 20px;
}

.MachineList_Category_title {
  font-size: 60px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 300;
  color: #ffc300;
  overflow-wrap: break-word;
  line-height: 1.2;
  margin-bottom: 30px;
}

.MachineList_V2_Category_title {
  font-size: 48px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 300;
  color: #141215;
  overflow-wrap: break-word;
  line-height: 60px;
}

@media (max-width: 646px) {
  .MachineList_V2_Category_title {
    font-size: 28px;
  }
}

.MachineList_img {
  width: 250px;
  max-width: 175px;
  margin-right: 15px;
  float: left;
}

.MachineList_Grid_img {
  width: 100%;
  height: 235px;
  float: left;
  object-fit: cover;
}

.MachineList_Grid_img img {
  height: 100%;
  height: 235px;
  object-fit: cover;
}

.checkbox {
  accent-color: #ffc300; /* Change the color of the checkbox */
  height: 16px; /* Adjust the height of the checkbox */
  width: 16px; /* Adjust the width of the checkbox */
}

.checkbox::before {
  width: 18px; /* Adjust the size of the checkmark */
  height: 18px; /* Adjust the size of the checkmark */
  vertical-align: middle;
}

.MachineList_dis {
  width: fit-content;
}

.MachineList_Grid_dis {
  width: fit-content;
  padding: 30px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media (max-width: 1454px) {
  .MachineList_Grid_dis {
    padding: 10px;
  }
}

@media (max-width: 1201px) {
  .MachineList_Grid_dis {
    padding: 20px;
  }
}

@media (max-width: 646px) {
  .MachineList_Grid_dis {
    padding: 15px;
  }
}

.MachineList_Title {
  color: #000;
  margin-top: 0;
  font-family: "Bebas Neue", sans-serif;
  font-size: 19px;
  font-weight: 500;
  color: #353535;
  line-height: 1.2em;
}

.MachineList_Grid_Title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 26px;
  font-weight: 400;
  color: #141215;
  line-height: 36px;
  cursor: pointer;
}

.MachineList_Grid_Hours_Title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #facd40;
  line-height: 24px;
  margin-top: 10px;
}

.MachineList_description {
  margin-top: 10px;
  line-height: 1.2em;
  text-transform: none;
  overflow-wrap: break-word;
  font-family: "Proxima-Nova", sans-serif;
  color: #353535;
  font-size: 14px;
}

.MachineList_Grid_description p {
  text-transform: none;
  overflow-wrap: break-word;
  font-family: "Proxima-Nova", sans-serif;
  margin: 10px 0;
  font-weight: 400;
  color: #141215;
  font-size: 16px;
  line-height: 28px;
}

.MachineList_Box {
  padding-bottom: 0 !important;
  padding-top: 10px;
  padding-left: 10px;
  font-family: "Proxima-Nova", sans-serif;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  /* flex-wrap: wrap; */
}

.MachineList_Grid_Box {
  padding-bottom: 0 !important;
  font-family: "Proxima-Nova", sans-serif;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* flex-wrap: wrap; */
}

.MachineList_Click_here_Text {
  display: flex;
  justify-content: flex-end;
  color: #ffc300;
  font-size: 11px;
  cursor: pointer;
  margin-top: 10px;
}

.MachineList_Click_here_Text:hover {
  color: #007bff;
}

.MachineList_Click_Grid_here_Text {
  padding: 11px 15px;
  background: #facd40;
  color: #141215;
  font-size: 16px;
  font-weight: 400;
  font-family: "Bebas Neue", sans-serif !important;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.MachineList_View_More {
  padding: 13px 30px;
  background: #141215;
  color: #facd40;
  font-size: 20px;
  font-weight: 400;
  font-family: "Bebas Neue", sans-serif !important;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  border: none;
  margin: auto;
}

.MachineList_Title_Box {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  flex-direction: column;
}

.MachineList_Title_Hover {
  cursor: pointer;
}

.MachineList_Title_Hover:hover {
  color: #65bec2;
}

@media screen and (max-width: 1200px) {
  .MachineList_View_More {
    padding: 11px 15px;
    font-size: 16px;
  }

  .dashboard_left_box {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .inventory_left_box {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .dashboard_selected_left_box {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .responsive_dashboard {
    flex-direction: column;
  }

  .dashboard_right_box {
    width: 100%;
  }

  .dashboard_right_box_without_animation {
    width: 100%;
  }

  .dashboard_slider_model_text {
    font-size: 41px;
    padding-top: 20px;
  }

  .dashboard_navigon_ink_box {
    margin-bottom: 5px;
    padding: 10px;
  }

  .dashboard_active_navigon_ink_box {
    margin-bottom: 5px;
    padding: 10px;
  }

  .dashboard_left_box {
    width: 100%;
    padding-left: 5px;
  }

  .inventory_left_box {
    width: 100%;
    padding-left: 5px;
  }

  .dashboard_selected_left_box {
    width: 100%;
    padding-left: 5px;
  }
}

@media (max-width: 991px) {
  .dashboard_slider_model {
    width: 30vw;
    max-width: 30vw;
    min-width: 30vw;
    height: 471px;
  }

  .dashboard_slider_model {
    width: 30vw;
    max-width: 30vw;
    min-width: 30vw;
    height: 471px;
  }

  .dashboard_slider_model_text {
    font-size: 23px;
    line-height: 1.5em;
  }
}

@media screen and (max-width: 981px) {
  .dashboard_Slider_Img {
    height: 655px;
  }
}

@media (max-width: 767px) {
  .dashboard_Slider_Text {
    width: auto;
  }

  .dashboard_Slider_Img {
    height: 463px;
  }

  .dashboard_slider_model_text {
    font-size: 16px;
    padding-top: 5px;
  }

  .dashboard_slider_model {
    height: 350px;
    padding-left: 10px;
  }

  .MachineList_Box {
    flex-wrap: wrap;
  }
}

@media (max-width: 598px) {
  .dashboard_Slider_Img {
    height: 400px;
  }
}

@media (max-width: 479px) {
  .dashboard_slider_model_text {
    font-size: 12px;
  }

  .dashboard_slider_model {
    padding-left: 5px;
    padding-right: 5px;
    height: auto;
    padding-top: 5px;
  }

  .dashboard_right_box {
    margin-bottom: 300px;
  }

  .dashboard_right_box_without_animation {
    margin-bottom: 00px;
  }

  .Carousel_navigation_part {
    display: none;
  }

  .dashboard_Slider_Text {
    font-size: 12px;
  }

  .dashboard_Slider_Img {
    height: 211px;
  }
}

@keyframes slideInFromBottom2 {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.home_bannar_text {
  font-family: "Bebas Neue", sans-serif !important;
  font-weight: 500;
  font-size: 60px;
  line-height: 72px;
  color: #fff;
}

@media only screen and (max-width: 1454px) {
  .home_bannar_text {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 1144px) {
  .home_bannar_text {
    font-size: 40px;
    line-height: 50px;
  }
}
.home_bannar_sub_text {
  font-family: "Proxima-Nova", sans-serif !important;
  font-weight: 700;
  font-size: 28px;
  line-height: 48px;
  color: #fff;
}

.home_bannar_btn {
  padding: 19px 25px;
  background: #facd40;
  font-family: "Bebas Neue", sans-serif !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-top: 30px;
  cursor: pointer;
}

@media only screen and (max-width: 1454px) {
  .home_bannar_sub_text {
    font-size: 24px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 1144px) {
  .home_bannar_sub_text {
    font-size: 20px;
    line-height: 30px;
  }

  .home_bannar_btn {
    font-size: 18px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 931px) {
  .home_bannar_text {
    font-size: 30px;
    line-height: 25px;
  }

  .home_bannar_sub_text {
    font-size: 16px;
    line-height: 20px;
  }

  .home_bannar_btn {
    font-size: 16px;
    line-height: 20px;
    padding: 12px 20px;
  }
}

.category_title {
  text-align: center;
  color: #141215;
  background: #facd40;
  font-size: 32px;
  line-height: 32px;
  padding: 17px 30px;
  font-family: "Bebas Neue", sans-serif !important;
  font-weight: 500;
}

@media only screen and (max-width: 1454px) {
  .category_title {
    font-size: 24px;
    line-height: 30px;
  }
}

.explore_title {
  font-family: "Bebas Neue", sans-serif !important;
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  color: #141215;
}
@media only screen and (max-width: 1454px) {
  .explore_title {
    font-size: 36px;
    line-height: 50px;
  }
}

.explore_sub_title {
  font-family: "Proxima-Nova", sans-serif !important;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #141215;
}
@media only screen and (max-width: 1454px) {
  .explore_sub_title {
    font-size: 20px;
  }
}

.banner_component {
  height: 80svh;
}

@media only screen and (max-width: 900px) {
  .banner_component {
    height: 100%;
  }

  .home_bannar_btn {
    margin-bottom: 50px;
  }
}

.home_banner_box {
  padding: 97px 129px;
}
@media only screen and (max-width: 1454px) {
  .home_banner_box {
    padding: 60px;
  }
}

/* Adjust styles for tablets */
@media (max-width: 768px) {
  .home_bannar_text {
    font-size: 48px;
    line-height: 56px;
  }
  .home_bannar_sub_text {
    font-size: 24px;
    line-height: 36px;
  }
  .banner_component {
    height: 40svh;
  }
  .home_banner_box {
    padding: 50px 64px;
  }
  .explore_title {
    font-size: 36px;
    line-height: 48px;
  }
  .explore_sub_title {
    font-size: 20px;
    line-height: 28px;
  }
  .category_title {
    font-size: 28px;
    line-height: 48px;
    padding: 15px 20px;
  }
  .home_bannar_btn {
    padding: 12px 18px;
    font-size: 16px;
    margin-top: 15px;
  }
}

@media (max-width: 600px) {
  .home_page_contact_us {
    padding: 100px 0px !important;
  }
}

@media (max-width: 639px) {
  .home_bannar_text {
    font-size: 25px;
    line-height: 40px;
    text-align: center; /* Optional: Center align for better mobile UX */
  }

  .home_bannar_sub_text {
    font-size: 20px;
    line-height: 24px;
  }
}

/* Adjust styles for mobile screens */
@media (max-width: 480px) {
  .home_bannar_text {
    font-size: 25px;
    line-height: 35px;
    text-align: center; /* Optional: Center align for better mobile UX */
  }

  .home_bannar_sub_text {
    font-size: 14px;
    line-height: 20px;
    text-align: center; /* Optional: Center align for better mobile UX */
  }
  .banner_component {
    height: 50svh;
  }
  .home_banner_box {
    padding: 30px 16px;
  }
  .explore_title {
    font-size: 18px;
    line-height: 32px;
    text-align: center; /* Optional: Center align for better presentation */
  }
  .explore_sub_title {
    font-size: 14px;
    line-height: 24px;
    text-align: center; /* Optional: Center align for better readability */
  }
  .category_title {
    font-size: 20px;
    line-height: 36px;
    padding: 12px 15px;
  }
  .home_bannar_btn {
    padding: 10px 15px;
    font-size: 14px;
    margin-top: 10px;
  }
}
