.header_v2_bg {
  background: #facd40;
  padding: 5px 0px;
}

.header_v2_top_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.logo_header_v2_bg {
  padding: 10px 0px;
  background: #fff;
}

.header_v2_title {
  text-transform: uppercase;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  line-height: 24px;
  font-size: 20px;
  color: #141215;
  cursor: pointer;
}

.logo_header_v2_logo {
  height: 80px;
}
@media only screen and (max-width: 1454px) {
  .logo_header_v2_logo {
    height: 65px;
  }
}

/* Adjust styles for tablets */
@media (max-width: 768px) {
  .header_v2_title {
    font-size: 18px;
    line-height: 22px;
  }

  .header_v2_top_container {
    flex-direction: column;
  }

  .logo_header_v2_logo {
    height: 45px;
  }
}

/* Adjust styles for mobile screens */
@media (max-width: 480px) {
  .header_v2_title {
    font-size: 12px;
    line-height: 20px;
    text-align: center; /* Optional: Center align for better mobile UX */
  }
}

/* Base styling */
.dropdown {
  position: relative;
  display: inline-block;
  margin: auto;
  /* display: flex;
  align-items: center; */
}

.dropdown-content {
  display: none;
  /* display: block; */
  width: 100%;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown content on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.m-dropdown {
  display: none;
}

@media (max-width: 1244px) {
  .dropdown {
    display: none;
  }

  .m-dropdown {
    display: block;
  }
}
