.Inventory_Title_Print {
  font-family: "Bebas Neue", sans-serif;
  font-size: 45px;
  text-align: left;
  overflow-wrap: break-word;
  color: #353535;
  line-height: 1.5;
  font-weight: 100;
}

.Inventory_Title_Copy {
  font-family: "Bebas Neue", sans-serif;
  font-size: 35px;
  text-align: left;
  overflow-wrap: break-word;
  color: #353535;
  line-height: 1.2;
  font-weight: 100;
}

.InventoryComponent_left_side_box_print {
  width: 60%;
  padding-right: 10px;
}

.InventoryComponent_left_side_box_copy {
  width: 60%;
  padding-right: 10px;
}

.InventoryComponent_right_side_box_Print {
  /* width: 55%; */
  padding-left: 10px;
  text-align: left;
  max-width: 500px;
  min-width: 400px;
  line-height: 1.1em;
  max-width: 300px;
  display: block;
}

.InventoryComponent_right_side_box_Copy {
  /* width: 55%; */
  /* padding-left: 10px; */
  text-align: left;
  /* max-width: 500px; */
  /* min-width: 400px; */
  line-height: 1.1em;
  /* max-width: 300px; */
  display: block;
}

.Print_Inventory_Title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 42px;
  text-align: left;
  overflow-wrap: break-word;
  color: #353535;
  /* font-weight: 300; */
  line-height: 1.2;
}

.Inventory_HOURS_Box {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 10px 0;
}

.Description_Box {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  word-wrap: break-word;
  line-height: 1.1em;
  font-size: 16px;
  font-weight: 400;
}

.Description_Title {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  color: #f4c900 !important;
  background: #363636 !important;
  padding: 8px 16px;
}

.Print_Description_Title {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  color: #f4c900 !important;
  background: transparent !important;
  padding: 8px 16px;
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
}

.Description_Sub_Title {
  line-height: 1.1em;
}

.Description_Box_Text {
  min-height: 150px;
  background-color: #fff;
  border: 1px solid #ececec;
}

.InventoryComponent_Box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.InventoryComponent_right_Box {
  display: flex;
  flex-wrap: wrap;
  width: 74%;
  align-items: center;
}

.InventoryComponent_left_side_box {
  width: 56%;
  padding-right: 10px;
}

.InventoryComponent_right_side_box {
  width: 40%;
  padding-left: 10px;
}

.InventoryComponent_Title {
  color: #353535;
  font-weight: 500;
  font-size: 30px;
  margin-top: 40px;
  text-align: left;
}

.InventoryComponent_left_side_box_Print {
  width: 40%;
}

.Print_InventoryComponent_Title {
  color: #353535;
  font-weight: 400;
  font-size: 25px;
  margin-top: 20px;
  text-align: left;
}

.Copy_InventoryComponent_Title {
  color: #353535;
  font-weight: 400;
  font-size: 30px;
  margin-top: 20px;
  text-align: left;
}

.InventoryComponent_Sub_Title {
  margin-bottom: 1px;
  color: #353535;
  line-height: 1.1em;
  font-size: 16px;
}

.image-gallery-svg {
  height: auto !important;
  width: 20px !important;
  background: rgba(0, 0, 0, 0.67);
  opacity: 1;
  padding: 20px 10px;
  box-shadow: none;
  border: 0 solid rgba(0, 0, 0, 1);
  border-radius: 5px;
}

.No_Machine {
  font-weight: bold;
  font-size: 20px;
}

.close_box {
  border: 2px solid #fff !important;
  padding: 10px !important;
}

.InventoryComponent_Price_Print_text {
  font-size: 18px;
  font-family: "Bebas Neue";
  color: #444;
  display: flex;
}

.InventoryComponent_Price {
  font-size: 27px;
  font-weight: 400;
  font-family: "Bebas Neue", sans-serif !important;
  color: #f4c900;
  margin-left: 5px;
}

.InventoryComponent_Print_Price {
  font-size: 18px;
  font-weight: 400;
  font-family: "Bebas Neue", sans-serif !important;
  color: #f4c900;
  margin-left: 5px;
}

.InventoryComponent_Price_Print_Code {
  font-size: 18px;
  font-weight: 400;
  font-family: "Bebas Neue", sans-serif !important;
  color: #444;
  margin-left: 5px;
}

.InventoryComponent_Price_Box {
  display: flex;
  align-items: flex-start;
  font-weight: 400;
  font-family: "Bebas Neue", sans-serif !important;
  margin-bottom: 5px;
}

.mr-2 {
  margin-right: 6px;
}

.InventoryComponent_left_side_box_Copy {
  width: 60%;
}

.InventoryComponent_right_side_box_Copy {
  width: 40%;
}

@media (max-width: 1200px) {
  .InventoryComponent_right_Box {
    width: 100%;
  }

  .InventoryComponent_Box {
    flex-direction: column;
  }

  .InventoryComponent_left_side_box {
    width: 100%;
    padding-right: 0px;
  }

  .InventoryComponent_right_side_box {
    width: 100%;
    padding-left: 0px;
  }
}

@media (max-width: 479px) {
  .InventoryComponent_right_Box {
    margin-bottom: 300px;
  }
}

.inventory_category_box {
  background: #fff;
  text-align: center;
  padding: 30px;
}

@media (max-width: 646px) {
  .inventory_category_box {
    background: #fff;
    text-align: center;
    padding: 10px;
  }
}

.inventory_category {
  background: #f5f5f5;
  width: auto;
  padding: 19px;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.inventory_category_active {
  background: #facd40;
  width: auto;
  padding: 19px;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.Inventory_Title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 48px;
  line-height: 60px;
  text-align: left;
  overflow-wrap: break-word;
  color: #141215;
  font-weight: 400;
}

.Inventory_sub_Title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 30px;
  line-height: 36px;
  text-align: left;
  overflow-wrap: break-word;
  color: #141215;
  font-weight: 400;
}

@media (max-width: 1454px) {
  .Inventory_Title {
    font-size: 38px;
    line-height: 50px;
  }

  .Inventory_sub_Title {
    font-size: 20px;
    line-height: 26px;
  }
}

@media (max-width: 635px) {
  .Inventory_Title {
    font-size: 28px;
    line-height: 30px;
  }

  .Inventory_sub_Title {
    font-size: 16px;
    line-height: 26px;
  }
}

.InventoryComponent_Price_text {
  font-size: 30px;
  line-height: 36px;
  font-family: "Bebas Neue", sans-serif;
  color: #141215;
  display: flex;
  align-items: center;
}

.InventoryComponent_Price {
  font-size: 30px;
  line-height: 36px;
  font-family: "Bebas Neue", sans-serif;
  color: #f4c900;
  margin-left: 5px;
}

.InventoryComponent_Price_Code {
  font-size: 30px;
  line-height: 36px;
  font-family: "Bebas Neue", sans-serif;
  color: #f4c900;
  margin-left: 5px;
}

@media (max-width: 1454px) {
  .InventoryComponent_Price_text {
    font-size: 27px;
  }
  .InventoryComponent_Price {
    font-size: 27px;
  }
  .InventoryComponent_Price_Code {
    font-size: 27px;
  }
}

@media (max-width: 635px) {
  .InventoryComponent_Price_text {
    font-size: 20px;
  }
  .InventoryComponent_Price {
    font-size: 20px;
  }
  .InventoryComponent_Price_Code {
    font-size: 20px;
  }
}

.price_box {
  padding: 50px;
  background: #fff;
}
@media (max-width: 646px) {
  .price_box {
    padding: 10px;
  }
}

.get_more_info {
  background: #facd40;
  padding: 19px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Bebas Neue", sans-serif;
  border: none;
  width: 100%;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1454px) {
  .get_more_info {
    font-size: 16px;
  }
}
@media (max-width: 1194px) {
  .get_more_info {
    font-size: 20px;
  }
}

.Documents_specifications {
  font-weight: 400;
  font-family: "Bebas Neue", sans-serif;
  font-size: 24px;
  line-height: 36px;
}

.Report_Text {
  font-family: "Bebas Neue", sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.Report_Box {
  padding: 50px;
  background: #fff;
  margin-top: 24px;
  margin-bottom: 100px;
}

@media (max-width: 1200px) {
  .Report_Box {
    margin-bottom: 10px;
  }
}

@media (max-width: 1454px) {
  .Report_Text {
    font-size: 30px;
    line-height: 50px;
  }
  .Report_Box {
    padding: 20px;
  }
}

@media (max-width: 635px) {
  .Report_Text {
    font-size: 20px;
  }
}

@media (max-width: 646px) {
  .Report_Box {
    padding: 10px;
  }
}

.PulseIcon_Box {
  background: #141215;
  padding: 8px;
  width: min-content;
  height: 18px;
  margin-top: -2px;
}

.PulseIcon_Box img {
  width: 18px;
}

.Report_Sub_Text {
  font-family: "Bebas Neue", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: #facd40;
}
