.Reconditioning_Process_title {
  font-family: "Bebas Neue", sans-serif;
  overflow-wrap: break-word;
  font-weight: 600;
  line-height: 1.2;
  color: #353535;
  text-align: left;
  font-size: 36px;
}

.Reconditioning_Process_Sub_title {
  font-family: "Proxima-Nova", sans-serif;
  overflow-wrap: break-word;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #353535;
  line-height: 1;
  font-size: 14px;
}
.Reconditioning_Process_Sub_title_bottom {
  font-family: "Proxima-Nova", sans-serif;
  overflow-wrap: break-word;
  margin-top: 0;
  /* margin-bottom: 1rem; */
  color: #353535;
  line-height: 1;
  font-size: 14px;
}
.Reconditioning_Process_Sub_title_bottom_tow {
  font-family: "Proxima-Nova", sans-serif;
  overflow-wrap: break-word;
  margin-top: 0;
  margin: 5px 0;
  color: #353535;
  line-height: 1;
  font-size: 14px;
}
.Reconditioning_Process_Sub_title_point {
  line-height: 1;
  font-size: 14px;
  font-weight: 400;
  box-sizing: inherit;
  overflow-wrap: break-word;
  text-align: -webkit-match-parent;
  color: #353535;
}

.Reconditioning_Process_Sub_titleSub {
  font-weight: 600;
  font-style: italic;
}

.quality_box {
  background: #141215;
  text-align: center;
  height: 100%;
}

.quality_title {
  font-family: "Proxima-Nova", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  color: #ffffff;
  padding: 30px;
  text-transform: capitalize;
}

.quality_img {
  padding-top: 30px;
}

.Process_Card_Title {
  color: #141215;
  padding: 30px 0 0 30px;
  font-family: "Bebas Neue", sans-serif;
  font-size: 32px;
  line-height: 36px;
}

.Options_Sub_Title {
  color: #141215;
  font-family: "Bebas Neue", sans-serif;
  font-size: 32px;
  line-height: 36px;
}

.Process_Card_Sub_Title {
  color: #141215;
  padding: 30px;
  font-family: "Proxima-Nova", sans-serif;
  font-size: 24px;
  line-height: 32px;
}

.customizable_services_text {
  font-family: "Proxima-Nova", sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
  padding: 30px;
  background: #141215;
}

.Process_Card_Index {
  background: #141215;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  height: max-content;
  font-family: "Bebas Neue", sans-serif;
  font-size: 48px;
  line-height: 60px;
  color: #ffffff;
  font-weight: 400;
}

.Process_Card_Box {
  background: #facd40;
  height: 100%;
}

.Process-img {
  height: 100%;
  object-fit: cover;
  width: -webkit-fill-available;
}

.process_ensures_box {
  background: #141215;
  font-family: "Proxima-Nova", sans-serif;
  font-size: 24px;
  line-height: 32px;
  padding: 121px 51px;
  color: #fff;
  font-weight: 400;
}

.Reconditioning_Option_Card {
  background: #facd40;
  padding: 0 60px;
  height: 100%;
}

.Reconditioning_Process_Contact_Us {
  /* padding: 0 30px; */
}

.Process_Card_IMG {
  object-fit: cover;
  object-position: center;
  width: 100%; /* Default width for smaller screens */
  max-width: 100px; /* Max width for very large screens */
}

.our_team {
  background: #facd40 !important;
  font-family: "Bebas Neue", sans-serif !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #141215 !important;
  border-radius: 0px !important;
  padding: 19px 25px !important;
  margin: 12px !important;
}

.about_us {
  background: #141215 !important;
  font-family: "Bebas Neue", sans-serif !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #facd40 !important;
  border-radius: 0px !important;
  padding: 19px 25px !important;
  margin: 12px !important;
}

.contact_us {
  background: #14121500 !important;
  font-family: "Bebas Neue", sans-serif !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #141215 !important;
  border-radius: 0px !important;
  padding: 19px 25px !important;
  border: 1px solid #facd40 !important;
  margin: 12px !important;
}
.contact_us_team {
  background: #fff !important;
  font-family: "Bebas Neue", sans-serif !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #141215 !important;
  border-radius: 0px !important;
  padding: 19px 25px !important;
  border: 1px solid #facd40 !important;
  margin: 12px !important;
}
/* Tablet (max-width: 1024px) */
@media (max-width: 1024px) {
  .quality_title {
    font-size: 20px;
    margin-top: 25px;
  }
  .Process_Card_Title {
    font-size: 28px;
    line-height: 32px;
  }

  .Options_Sub_Title {
    font-size: 28px;
    line-height: 32px;
  }
  .Process_Card_Sub_Title {
    font-size: 20px;
    line-height: 28px;
  }
  .customizable_services_text {
    font-size: 20px;
    line-height: 28px;
    padding: 20px;
    background: #141215;
  }

  .process_ensures_box {
    font-size: 20px;
    line-height: 28px;
    padding: 50px 30px;
  }
  .Process_Card_Index {
    font-size: 40px;
    line-height: 52px;
  }
  .Reconditioning_Option_Card {
    background: #facd40;
    padding: 0 40px;
  }
  .Process_Card_IMG {
    width: 100px; /* Set width to 100px for screens larger than or equal to 1024px */
  }
}

@media (max-width: 900px) {
  .process_ensures_box {
    padding: 20px 30px;
  }

  .dashboard_banner {
    height: 460px !important;
  }
  .Process_Card_IMG {
    width: 90px; /* Set width to 90px for screens larger than or equal to 900px */
  }
}

/* Mobile (max-width: 768px) */
@media (max-width: 768px) {
  .quality_title {
    font-size: 18px;
    margin-top: 20px;
  }
  .Process_Card_Title {
    font-size: 24px;
    line-height: 28px;
    padding: 20px 0 0 20px;
  }
  .Options_Sub_Title {
    font-size: 24px;
    line-height: 28px;
  }
  .Process_Card_Sub_Title {
    font-size: 18px;
    line-height: 24px;
    padding: 15px;
  }
  .customizable_services_text {
    font-size: 18px;
    line-height: 24px;
    padding: 15px;
  }
  .process_ensures_box {
    font-size: 18px;
    line-height: 24px;
  }
  .Process_Card_Index {
    font-size: 36px;
    line-height: 48px;
    padding: 4px 8px;
  }
  .Reconditioning_Option_Card {
    background: #facd40;
    padding: 0 30px;
  }
  .Process_Card_IMG {
    width: 80px; /* Set width to 80px for screens larger than or equal to 768px */
  }
}

/* Mobile (max-width: 480px) */
@media (max-width: 480px) {
  .quality_title {
    font-size: 16px;
    margin-top: 15px;
  }
  .Process_Card_Title {
    font-size: 20px;
    line-height: 24px;
    padding: 15px 0 0 15px;
  }
  .Options_Sub_Title {
    font-size: 20px;
    line-height: 24px;
  }
  .Process_Card_Sub_Title {
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
  }
  .customizable_services_text {
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
  }
  .process_ensures_box {
    font-size: 16px;
    line-height: 20px;
  }
  .Process_Card_Index {
    font-size: 30px;
    line-height: 40px;
    padding: 4px 6px;
  }
  .Reconditioning_Option_Card {
    background: #facd40;
    padding: 0 10px;
  }
  .Process_Card_IMG {
    width: 70px; /* Set width to 70px for screens larger than or equal to 480px */
  }
}
